// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";
import store from "../assets/images/stall1.png";
import donation from "../assets/images/donation1.png";
import succss from "../assets/images/success2.png";

import Sidebar from "../components/Sidebar";

import {getUser} from "../auth/Auth";
import {getRequest, postRequest} from "../routes/Routes";
import {toast} from "react-toastify";

const GroupAdminCheckout = () => {
    const navigate = useNavigate();
    const [booking, setBooking] = useState(null),
        [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingEmail, setLoadingEmail] = useState(false);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
        setBooking(JSON.parse(sessionStorage.getItem('groupBooking')));
    }, []);

    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => {
        setLoading(true);
        let values = {
            ...booking,
            token: user.token,
            endpoint: booking.id ? '/api/booking/update/group/' + booking.id : '/api/booking/admin/group'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    sessionStorage.removeItem('groupBooking');
                    setBooking({...response.data.booking, member_count: booking.member_count});
                    setPopupOpen(true);
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    };

    const closePopups = () => {
        setLoadingEmail(true);
        getRequest('/api/booking/email/' + booking.id, user.token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/admin/dashboard/groupBooking');
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoadingEmail(false);
            setPopupOpen(false);
        });
    };

    const handleCancel = () => {
        navigate('/admin/dashboard/groupBooking/add');
    };

    if (booking && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Checkout</h1>
                            <span>Group Booking / Checkout</span>
                        </div>
                        <div className="checkout-main-wrapper">
                            <div className="leftSide-check">
                                <div className="personDetail">

                                    <div className="lineSingle">
                                        <span className="lineRow-person">Full Name :</span>
                                        <span className="lineRow-person">{booking.full_name}</span>
                                    </div>

                                    <div className="lineSingle">
                                        <span className="lineRow-person">Name of Contact Person :</span>
                                        <span className="lineRow-person">{booking.person_name}</span>
                                    </div>

                                    <div className="lineSingle">
                                        <span className="lineRow-person">Contact Number :</span>
                                        <span className="lineRow-person">{booking.phone}</span>
                                    </div>

                                    <div className="lineSingle">
                                        <span className="lineRow-person">Email Address :</span>
                                        <span className="lineRow-person">{booking.email}</span>
                                    </div>
                                </div>
                                <div className="personDetail grpCat">
                                    <div className="lineSingle">
                                        <span className="lineRow-person">Group Category</span>
                                        <span className="lineRow-person">{booking.group_category}</span>
                                    </div>
                                </div>
                                <div className="tLableTxt">
                                    <span>T-Shirt Size Details</span>
                                </div>
                                <div className="tSize quntiLbl">
                                    <div className="lineSingle">
                                        <span className="lineRow-person">T-Shirt Size</span>
                                        <span className="lineRow-person">Quantity</span>
                                    </div>
                                </div>
                                <div className="registData tDatalbls">
                                    {booking.booking_t_shirts.map((item, index) => (
                                        <div className="lineSingle" key={index}>
                                            <span
                                                className="lineRow-person">{item.t_shirt_id ? item.t_shirt.description + " (" + item.t_shirt.size + ")" : "No T-Shirt"} :</span>
                                            <span
                                                className="lineRow-person">{String(item.quantity).padStart(2, '0')}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="personDetail">
                                    <div className="lineSingle">
                                        <span className="lineRow-person">Total T-Shirt Quantity :</span>
                                        <span
                                            className="lineRow-person">{String(booking.member_count).padStart(2, '0')}</span>
                                    </div>
                                </div>
                                <div className="personDetail">
                                    <div className="lineSingle">
                                        <span className="lineRow-person">Registration Fee :</span>
                                        <span className="lineRow-person">LKR {booking.member_count * 1600}</span>
                                    </div>
                                    <div className="lineSingle">
                                        <span className="lineRow-person">Extra Donation :</span>
                                        <span
                                            className="lineRow-person">LKR {booking.donation - (booking.member_count * 1600)}</span>
                                    </div>
                                </div>
                                <div className="totDonation donGrp">
                                    <div className="lineSingle">
                                        <span className="lineRow-person">Donation Amount :</span>
                                        <span className="lineRow-person">LKR {booking.donation}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="rgtSide-check">
                                <div className="cardInCashMain">
                                    <div className="flx-crd">
                                        <div className="lft">
                                            <span>Cash in hand</span>
                                            <span>Member has collected the registration fee in cash</span>
                                        </div>
                                        <div className="svgIco">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                                 viewBox="0 0 28 28" fill="none">
                                                <path
                                                    d="M22.5168 9.23974V15.2481C22.5168 18.8414 20.4635 20.3814 17.3835 20.3814H7.12844C6.60344 20.3814 6.10178 20.3348 5.63511 20.2298C5.34345 20.1831 5.06346 20.1015 4.80679 20.0081C3.05679 19.3548 1.99512 17.8381 1.99512 15.2481V9.23974C1.99512 5.6464 4.04844 4.10645 7.12844 4.10645H17.3835C19.9968 4.10645 21.8751 5.21477 22.3768 7.74644C22.4585 8.21311 22.5168 8.6914 22.5168 9.23974Z"
                                                    stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M26.0179 12.7394V18.7478C26.0179 22.3411 23.9646 23.881 20.8846 23.881H10.6296C9.76623 23.881 8.98457 23.7644 8.30791 23.5078C6.91957 22.9944 5.97456 21.9328 5.63623 20.2294C6.1029 20.3344 6.60456 20.381 7.12956 20.381H17.3846C20.4646 20.381 22.5179 18.8411 22.5179 15.2478V9.23939C22.5179 8.69106 22.4712 8.20109 22.3779 7.74609C24.5946 8.21276 26.0179 9.77606 26.0179 12.7394Z"
                                                    stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M12.2485 15.33C13.9495 15.33 15.3285 13.951 15.3285 12.2499C15.3285 10.5489 13.9495 9.16992 12.2485 9.16992C10.5474 9.16992 9.16846 10.5489 9.16846 12.2499C9.16846 13.951 10.5474 15.33 12.2485 15.33Z"
                                                    stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.57666 9.68262V14.816" stroke="#292D32" stroke-width="1.5"
                                                      stroke-miterlimit="10" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                                <path d="M18.9253 9.68359V14.817" stroke="#292D32" stroke-width="1.5"
                                                      stroke-miterlimit="10" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="payOptions">
                                    <div className="payOptionline">
                                        <div className="svgIcoPay">
                                            <img src={store}></img>
                                        </div>
                                        <div className="taglineOption">
                                            <span>The event will also feature stalls, interactive games, photo stations, and several entertainment items.</span>
                                        </div>
                                    </div>
                                    <div className="payOptionline">
                                        <div className="svgIcoPay">
                                            <img src={donation}></img>
                                        </div>
                                        <div className="taglineOption">
                                            <span>All proceeds from the event will be donated to the Apeksha Cancer Hospital in Maharagama</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="para-def-pay">
                                    <p>By clicking checkout button, you agree to with our terms & Conditions and also
                                        acknowledge that you can not change or alter any of the details you filled in
                                        this
                                        form after it is submitted. </p>
                                </div>
                                <button type="button" className="onFormCancle" onClick={handleCancel}
                                        style={{width: "100%", marginBottom: "2%"}}>
                                    BACK
                                </button>
                                <button className="payGrpCheckout" type="submit" onClick={openPopup}>
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    CHECKOUT
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Popup/Modal */}
                    {isPopupOpen && (
                        <div className="popupWindow">
                            <div className="popup-content">
                                <img src={succss}></img>
                                <span className="popUpThanks">Thank You</span>
                                <span className="tagThanks">Your registration was successful.</span>
                                <span className="spcTag">Thank you for registering for Run For Their Lives 2024 in aid of the Apeksha Cancer Hospital.</span>
                                <p>A receipt confirming your registration will be sent to your email address shortly. We
                                    look forward to having you with us on the 25th of February 2024!</p>
                                <button onClick={closePopups} className="sendGrpPop">
                                    {loadingEmail ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Send To Inbox
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return null
    }
};

export default GroupAdminCheckout;
