// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";
import dp from "../assets/images/dp.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {getUser} from "../auth/Auth";
import {loadBookingDashboard, loadLimitedBookings, loadLimitedGroupBookings} from "../common/Common";

const AdminDashboard = () => {
    const navigate = useNavigate(),
        [user, setUser] = useState(null),
        [loadingContent, setLoadingContent] = useState(true),
        [individualBookings, setIndividualBookings] = useState([]),
        [groupBookings, setGroupBookings] = useState([]),
        [tShirtStats, setTShirtStats] = useState({total: 0, reserved: 0, remaining: 0}),
        [bookingDashboard, setBookingDashboard] = useState({
            individualParticipants: 0,
            groupParticipants: 0,
            cashDonations: 0.00,
            onlineDonations: 0.00
        });

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadLimitedBookings(3, data.token).then(data => {
                setIndividualBookings(data);
            }).catch(() => null);
            loadLimitedGroupBookings(3, data.token).then(data => {
                setGroupBookings(data);
            }).catch(() => null);
            loadBookingDashboard(data.token).then(data => {
                setBookingDashboard(data);
                setLoadingContent(false);
                let total = 0,
                    remaining = 0;
                data.tShirts.forEach((tShirt, index) => {
                    total += tShirt.quantity;
                    remaining += tShirt.remaining;
                });
                setTShirtStats({
                    ...tShirtStats,
                    total: total,
                    reserved: total - remaining,
                    remaining: remaining,
                });
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const tShirtBody = () => {
        let body,
            left = [],
            right = [];
        bookingDashboard.tShirts.forEach((tShirt, index) => {
            if ((index + 1) % 2 === 0) {
                right.push(
                    <div className="singleDetail">
                        <div className="group">
                            {tShirt.size} <span>({tShirt.description})</span>
                        </div>
                        <div className="group gsp" id="xsRem">
                            {tShirt.remaining} <span id="xsTot">/{tShirt.quantity}</span>
                        </div>
                    </div>
                );
            } else {
                left.push(
                    <div className="singleDetail">
                        <div className="group">
                            {tShirt.size} <span>({tShirt.description})</span>
                        </div>
                        <div className="group gsp" id="xsRem">
                            {tShirt.remaining} <span id="xsTot">/{tShirt.quantity}</span>
                        </div>
                    </div>
                );
            }
        });
        body = (
            <>
                <div className="left-graph">{left}</div>
                <div className="right-graph">{right}</div>
            </>
        );
        return body;
    }

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Dashboard</h1>
                            <span>Here's the summary of your activities</span>
                        </div>
                        <div className="cardRow">
                            <div className="singleCard">
                                <div className="topRow">
                                    <div className="frsLn">
                                    <span
                                        id="totParticipant">{bookingDashboard.individualParticipants + bookingDashboard.groupParticipants}</span>
                                        <div className="txtCardIn">Total Participant</div>
                                    </div>
                                    <div className="firLinIco">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.34325 6.34067C5.28491 6.33484 5.21491 6.33484 5.15075 6.34067C3.76241 6.294 2.65991 5.1565 2.65991 3.7565C2.65991 2.32734 3.81491 1.1665 5.24991 1.1665C6.67908 1.1665 7.83991 2.32734 7.83991 3.7565C7.83408 5.1565 6.73158 6.294 5.34325 6.34067Z"
                                                stroke="#FEFEFE"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M9.57241 2.3335C10.7041 2.3335 11.6141 3.24933 11.6141 4.37516C11.6141 5.47766 10.7391 6.376 9.64825 6.41683C9.60158 6.411 9.54908 6.411 9.49658 6.41683"
                                                stroke="#FEFEFE"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M2.42667 8.4935C1.015 9.4385 1.015 10.9785 2.42667 11.9177C4.03084 12.991 6.66167 12.991 8.26584 11.9177C9.6775 10.9727 9.6775 9.43266 8.26584 8.4935C6.6675 7.426 4.03667 7.426 2.42667 8.4935Z"
                                                stroke="#FEFEFE"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M10.6982 11.6665C11.1182 11.579 11.5149 11.4098 11.8416 11.159C12.7516 10.4765 12.7516 9.35067 11.8416 8.66817C11.5207 8.42317 11.1299 8.25984 10.7157 8.1665"
                                                stroke="#FEFEFE"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bottomRow">
                                    <div className="frsLn">
                                        <div id="indPar" className="sec-num">
                                            {bookingDashboard.individualParticipants}<span
                                            id="totParticipant">/{bookingDashboard.individualParticipants + bookingDashboard.groupParticipants}</span>
                                        </div>
                                        <div className="txtCardIn">Individual Participant</div>
                                    </div>
                                    <div className="secsLn">
                                        <div id="totParticipant" className="sec-num">
                                            {bookingDashboard.groupParticipants}<span>/{bookingDashboard.individualParticipants + bookingDashboard.groupParticipants}</span>
                                        </div>
                                        <div className="txtCardIn">Group Participant</div>
                                    </div>
                                </div>
                            </div>
                            <div className="singleCard whiteCard">
                                <div className="topRow">
                                    <div className="frsLn">
                                    <span
                                        id="totDonation">Rs. {bookingDashboard.cashDonations + bookingDashboard.onlineDonations}</span>
                                        <div className="txtCardIn">Total Donations</div>
                                    </div>
                                    <div className="firLinIco">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="17"
                                            viewBox="0 0 18 17"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.67107 10.8339C9.20317 11.366 10.0405 11.3866 10.5478 10.8793L11.5832 9.84396C12.0245 9.40261 12.008 8.6684 11.546 8.20642C11.0428 7.7032 10.6468 7.74444 10.2055 7.95481L7.96573 9.03963C7.52438 9.24999 7.1284 9.29124 6.62517 8.78802C6.1632 8.32604 6.1467 7.59183 6.58805 7.15047L7.62337 6.11515C8.13072 5.6078 8.96805 5.62843 9.50015 6.16052"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M6.60791 6.0249L11.5577 10.9746"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M13.2076 12.6248C15.4857 10.3467 15.4857 6.65327 13.2076 4.37521C10.9296 2.09715 7.2361 2.09715 4.95804 4.37521C2.67998 6.65327 2.67998 10.3467 4.95804 12.6248C7.2361 14.9028 10.9296 14.9028 13.2076 12.6248Z"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M3.1665 14.8325H14.8332"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bottomRow">
                                    <div className="frsLn">
                                        <div id="webxPay" className="sec-num">
                                            Rs. {bookingDashboard.onlineDonations}
                                        </div>
                                        <div className="txtCardIn">Webxpay Payments</div>
                                    </div>
                                    <div className="secsLn">
                                        <div id="cash" className="sec-num">
                                            Rs. {bookingDashboard.cashDonations}
                                        </div>
                                        <div className="txtCardIn">Cash In Hand</div>
                                    </div>
                                </div>
                            </div>

                            <div className="singleCard ultraWhiteCard">
                                <div className="topRow">
                                    <div className="frsLn">
                                        <span id="totTshirt">{tShirtStats.total}</span>
                                        <div className="txtCardIn">Total T-Shirts</div>
                                    </div>
                                    <div className="firLinIco">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="17"
                                            viewBox="0 0 18 17"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.67107 10.8339C9.20317 11.366 10.0405 11.3866 10.5478 10.8793L11.5832 9.84396C12.0245 9.40261 12.008 8.6684 11.546 8.20642C11.0428 7.7032 10.6468 7.74444 10.2055 7.95481L7.96573 9.03963C7.52438 9.24999 7.1284 9.29124 6.62517 8.78802C6.1632 8.32604 6.1467 7.59183 6.58805 7.15047L7.62337 6.11515C8.13072 5.6078 8.96805 5.62843 9.50015 6.16052"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M6.60791 6.0249L11.5577 10.9746"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M13.2076 12.6248C15.4857 10.3467 15.4857 6.65327 13.2076 4.37521C10.9296 2.09715 7.2361 2.09715 4.95804 4.37521C2.67998 6.65327 2.67998 10.3467 4.95804 12.6248C7.2361 14.9028 10.9296 14.9028 13.2076 12.6248Z"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M3.1665 14.8325H14.8332"
                                                stroke="#292D32"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="bottomRow">
                                    <div className="frsLn">
                                        <div id="tshirReserved" className="sec-num">
                                            {tShirtStats.reserved} <span id="totTshirt">/ {tShirtStats.total}</span>
                                        </div>
                                        <div className="txtCardIn">T-Shirts Reserved</div>
                                    </div>
                                    <div className="secsLn">
                                        <div id="tshirtRemain" className="sec-num">
                                            {tShirtStats.remaining}<span id="totTshirt">/ {tShirtStats.total}</span>
                                        </div>
                                        <div className="txtCardIn">T-Shirts Remaining</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* start the tshirt details */}
                        <div className="remTsDetail">
                            <div className="rmTMain">
                                <span className="remTMName">Remaining T-Shirts</span>
                                <div className="remTmainGraph">
                                    {tShirtBody()}
                                </div>
                            </div>
                        </div>

                        {/* individual group booking details */}
                        <div className="indGrpBookMain">
                            <div className="indBook">
                                <div className="indBookTopic">
                                    <span>Individual Booking</span>
                                    <span id="vAllIndividual"
                                          onClick={() => navigate("/admin/dashboard/individualBooking")}>View All</span>
                                </div>
                                {individualBookings.map((item, index) => (
                                    <div className="singleBookRecord" key={index}>
                                        <img src={logo}/>
                                        <div className="userDtl">
                                        <span className="userNme" id="unme1">
                                          {item.full_name}
                                        </span>
                                            <span className="userEml" id="umel1">
                                            {item.email}
                                        </span>
                                        </div>
                                        <div className="dateAndTime">
                                        <span className="dt" id="udate1">
                                            {(new Date(item.created_at)).toLocaleDateString()}
                                        </span>
                                            <span className="tim" id="utime1">
                                            {(new Date(item.created_at)).toLocaleTimeString()}
                                        </span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="indBook">
                                <div className="indBookTopic">
                                    <span>Group Booking</span>
                                    <span id="vAllGroup"
                                          onClick={() => navigate("/admin/dashboard/groupBooking")}>View All</span>
                                </div>

                                {groupBookings.map((item, index) => (
                                    <div className="singleBookRecord" key={index + "Group"}>
                                        <img src={logo}/>
                                        <div className="userDtl">
                                        <span className="userNme" id="unme1">
                                          {item.full_name}
                                        </span>
                                            <span className="userEml" id="umel1">
                                            {item.email}
                                        </span>
                                        </div>
                                        <div className="dateAndTime">
                                        <span className="dt" id="udate1">
                                            {(new Date(item.created_at)).toLocaleDateString()}
                                        </span>
                                            <span className="tim" id="utime1">
                                            {(new Date(item.created_at)).toLocaleTimeString()}
                                        </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="indGrpBookMain">
                            <div className="indBook" style={{width: "100%"}}>
                                <div className="indBookTopic">
                                    <span>User Leaderboard</span>
                                    <span/>
                                </div>
                                <div className="row row-cols-4 user-leaderboard-user-list">
                                    {bookingDashboard.userLeaderboard.map((item, index) => (
                                        <div className="col" key={index}
                                             onClick={() => navigate("/admin/dashboard/bookings/user/" + item.id)}>
                                            <div>
                                                <img src={dp}/>
                                                <div className="user-detail-container">
                                                    <span className="user-name" id="unme1">
                                                      {item.name}
                                                    </span>
                                                    <span className="user-email" id="umel1">
                                                        {item.email}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="user-detail-container" style={{textAlign: "left"}}>
                                                    <span className="user-name" id="unme1" style={{fontSize: 16}}>
                                                      {item.total_participants}
                                                    </span>
                                                    <span className="user-email" id="umel1"
                                                          style={{textTransform: "capitalize"}}>
                                                        Total Participants
                                                    </span>
                                                </div>
                                                <div className="user-detail-container">
                                                    <span className="user-email" id="unme1"
                                                          style={{textTransform: "capitalize"}}>
                                                      Individual {item.individual_participants}
                                                    </span>
                                                    <span className="user-email" id="umel1"
                                                          style={{textTransform: "capitalize"}}>
                                                        Group {item.group_participants}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AdminDashboard;
