import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {getUser} from "./auth/Auth";

import './App.css';

import LoginPg from './views/loginPage';
import AdminDashboard from './views/AdminDashboard';
import AdminIndividualBook from './views/AdminIndBooking';
import AddIndiRecAdmin from './views/AdminAddIndividualRec';
import IndividualAdminChecout from './views/AdminCheckoutIndividual';
import AdminGroupBook from './views/AdminGroupBooking';
import AddGroupRecAdmin from './views/AdminAddGroupBooking';
import GroupAdminCheckout from './views/AdminCheckoutGroup';
import TshirtOverview from './views/AdminTshirtOverview';
import AddTshirts from './views/AdminEditTshirt';
import GAlleryOverview from './views/AdminGalleryView';
import AddImg from './views/AdminAddImage';
import PartnerOver from './views/AdminPartnersView';
import AddPartners from './views/AdminAddPartners';
import AdminUsers from './views/AdminUsersView';
import AddUsers from './views/AdminAddUsers';

function App() {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    if (user !== undefined) {
        return (
            <Router>
                <div id="headerandbody">
                    <Routes>
                        <Route exact path='/'
                               element={<LoginPg emailLabel="Email" title="Welcome Back, Admin!"
                                                 usernameLabel="Username"
                                                 passwordLabel="Password"/>}/>
                        <Route exact strict path="/admin/dashboard"
                               element={user && user.role.name === "ADMIN" ? <AdminDashboard/> :
                                   <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/individualBooking"
                               element={user ? <AdminIndividualBook/> :
                                   <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/individualBooking/pending"
                               element={user ? <AdminIndividualBook/> :
                                   <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/individualBooking/checkin"
                               element={user ? <AdminIndividualBook/> :
                                   <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/individualBooking/add"
                               element={user ? <AddIndiRecAdmin/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/individualBooking/edit/:bookingId"
                               element={user ? <AddIndiRecAdmin/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/individualBooking/checkout"
                               element={user ? <IndividualAdminChecout/> : <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/groupBooking"
                               element={user ? <AdminGroupBook/> : <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/groupBooking/pending"
                               element={user ? <AdminGroupBook/> : <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/groupBooking/checkin"
                               element={user ? <AdminGroupBook/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/groupBooking/add"
                               element={user ? <AddGroupRecAdmin/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/groupBooking/edit/:bookingId"
                               element={user ? <AddGroupRecAdmin/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/groupBooking/checkout"
                               element={user ? <GroupAdminCheckout/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/tshirt/overview"
                               element={user && user.role.name === "ADMIN" ? <TshirtOverview/> :
                                   <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/tshirt/add"
                               element={user && user.role.name === "ADMIN" ? <AddTshirts/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/tshirt/edit/:tShirtId"
                               element={user && user.role.name === "ADMIN" ? <AddTshirts/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/gallery"
                               element={user && user.role.name === "ADMIN" ? <GAlleryOverview/> :
                                   <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/gallery/add"
                               element={user && user.role.name === "ADMIN" ? <AddImg/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/gallery/edit/:galleryId"
                               element={user && user.role.name === "ADMIN" ? <AddImg/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/partners"
                               element={user && user.role.name === "ADMIN" ? <PartnerOver/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/partners/add"
                               element={user && user.role.name === "ADMIN" ? <AddPartners/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/partners/edit/:partnerId"
                               element={user && user.role.name === "ADMIN" ? <AddPartners/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/users"
                               element={user && user.role.name === "ADMIN" ? <AdminUsers/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/users/add"
                               element={user && user.role.name === "ADMIN" ? <AddUsers/> : <Navigate to="/login"/>}/>
                        <Route path="/admin/dashboard/users/edit/:userId"
                               element={user && user.role.name === "ADMIN" ? <AddUsers/> : <Navigate to="/login"/>}/>
                        <Route exact strict path="/admin/dashboard/bookings/user/:userId"
                               element={user && user.role.name === "ADMIN" ? <AdminIndividualBook userBooking={true}/> :
                                   <Navigate to="/login"/>}/>
                        <Route path='*' element={<Navigate to='/'/>}/>
                    </Routes>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
                </div>
            </Router>
        );
    }
}

export default App;

