// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {loadAvailableTShirts, loadBooking} from "../common/Common";
import {getUser} from "../auth/Auth";

const AddIndiRecAdmin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(true),
        [tShirts, setTShirts] = useState([]),
        [bookingId] = useState(decodeURI(window.location.href.split('/').at(-1)));

    const [formData, setFormData] = useState({
        fullName: '',
        dateOfBirth: '',
        contactNumber: '',
        tShirt: '',
        email: '',
        donationAmount: '',
        agreeTerms: false,
    });

    useEffect(() => {
        loadAvailableTShirts().then(data => {
            setTShirts(data);
            setLoadingContent(false);
        }).catch(() => null);
        getUser().then((data) => {
            setUser(data);
            if (bookingId !== 'add') {
                setLoadingContent(true);
                loadBooking(bookingId, data.token).then(data => {
                    const tempFormData = {
                        fullName: data.full_name,
                        dateOfBirth: data.date_of_birth,
                        contactNumber: data.phone,
                        email: data.email,
                        donationAmount: data.donation,
                        tShirt: JSON.stringify(data.t_shirt),
                        t_shirt_id: data.t_shirt_id,
                        id: data.id,
                        status: data.status,
                        payment: data.payment,
                        checkin: data.checkin,
                        checkin_count: data.checkin_count,
                        checked_in_by: data.checked_in_by
                    }
                    setFormData(tempFormData);
                    setLoadingContent(false);
                }).catch(() => null);
            } else {
                const booking = JSON.parse(sessionStorage.getItem('booking'));
                const tempFormData = {
                    fullName: booking.full_name,
                    dateOfBirth: booking.date_of_birth,
                    contactNumber: booking.phone,
                    email: booking.email,
                    donationAmount: booking.donation,
                    tShirt: JSON.stringify(booking.t_shirt)
                }
                setFormData(tempFormData);
            }
        }).catch(() => null);
    }, []);

    useEffect(() => {
        if (formData.hasOwnProperty("id")) {
            let bookingTShirt = formData.tShirt === "" ? null : JSON.parse(formData.tShirt);
            if (bookingTShirt && !bookingTShirt.hasOwnProperty("remaining")) {
                tShirts.forEach((tShirt) => {
                    if (tShirt.id === bookingTShirt.id) {
                        bookingTShirt.remaining = tShirt.remaining;
                    }
                });
                setFormData({...formData, tShirt: JSON.stringify(bookingTShirt)});
            }
        }
    }, [tShirts, formData]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        let values = {
            full_name: formData.fullName,
            email: formData.email,
            phone: formData.contactNumber,
            date_of_birth: formData.dateOfBirth,
            t_shirt: formData.tShirt === "" ? null : JSON.parse(formData.tShirt),
            donation: formData.donationAmount,
            id: formData.hasOwnProperty("id") ? formData.id : null
        };
        sessionStorage.setItem('booking', JSON.stringify(values));
        setLoading(false);
        navigate('/admin/dashboard/individualBooking/checkout');
    };

    const handleCancel = () => {
        navigate(formData.hasOwnProperty("id") && formData.status !== "Confirmed" ? '/admin/dashboard/individualBooking/pending' : formData.hasOwnProperty("id") && formData.checkin ? '/admin/dashboard/individualBooking/checkin' : '/admin/dashboard/individualBooking');
    };

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Individual Booking</h1>
                            <span>Add/ Remove / Edit Individual Booking</span>
                        </div>
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="frm-row single-row">
                                    <div className="one-row">
                                        <label htmlFor="fullName">Full Name *</label>
                                        <input type="text" id="fullName" name="fullName" value={formData.fullName}
                                               onChange={handleChange} placeholder="Tania Sullivan" required/>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="dateOfBirth">Date of Birth *</label>
                                        <input type="date" id="dateOfBirth" name="dateOfBirth"
                                               value={formData.dateOfBirth}
                                               onChange={handleChange} placeholder="DD/MM/YYYY" required/>
                                    </div>
                                </div>
                                <div className="frm-row single-row contact-row">
                                    <div className="one-row">
                                        <label htmlFor="contactNumber">Contact Number *</label>
                                        <div className="contact-input">
                                            <div className="contact-input">
                                                <input
                                                    type="tel"
                                                    id="contactNumber"
                                                    name="contactNumber"
                                                    value={formData.contactNumber}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="+94 77 222 3333"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="tShirt">T-shirt Size *</label>
                                        <select id="tShirt" name="tShirt" value={formData.tShirt}
                                                onChange={handleChange}>
                                            <option value="">Select Size</option>
                                            {tShirts.map((item, index) => (
                                                <option value={JSON.stringify(item)} key={index}
                                                        disabled={formData.hasOwnProperty("id") ? formData.t_shirt_id === item.id ? false : item.remaining === 0 : item.remaining === 0}>
                                                    {item.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="frm-row single-row">
                                    <div className="one-row">
                                        <label htmlFor="email">Email Address *</label>
                                        <span>(This is where you would get your receipt to, so type carefully)</span>
                                        <input type="email" id="email" name="email" value={formData.email}
                                               onChange={handleChange} placeholder="user@email.com" required/>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="donationAmount">Donation Amount *</label>
                                        <span>(The minimum donation amount should be LKR 1600)</span>
                                        <input type="number" id="donationAmount" name="donationAmount"
                                               value={formData.donationAmount} onChange={handleChange}
                                               placeholder="LKR 1600"
                                               min={1600} required/>
                                    </div>
                                </div>

                                {formData.hasOwnProperty("id") && formData.status !== "Confirmed" &&
                                <div className="frm-row single-row halfRow">
                                    <div className="one-row">
                                        <label htmlFor="grpSize">Status</label>
                                        <span>
                                            {formData.status}
                                        </span>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="amount">IPG Comment</label>
                                        <span>
                                            {formData.payment ? formData.payment.comment : ""}
                                        </span>
                                    </div>
                                </div>
                                }

                                <div className="termsandCondition">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="agreeTerms"
                                            name="agreeTerms"
                                            checked={formData.agreeTerms}
                                            onChange={handleChange}
                                            required
                                        />
                                        <span>I, the participant, intending to be legally bound and hereby waive or release any and all right and claims for damages or injuries that I may have against the organizers (The Rotaract Club of Colombo Uptown) and all the official volunteers assisting with the event for any and all injuries to me or my personal property. This release includes all injuries and/or damages suffered by me before, during or after the ev...</span>
                                    </label>
                                </div>
                                <div className="buttin-set-sub">
                                    <button type="button" className="onFormCancle" onClick={handleCancel}>Cancel
                                    </button>
                                    <button type="submit" className="onFormSubmit">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AddIndiRecAdmin;
