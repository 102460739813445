import {getRequest} from "../routes/Routes";
import {toast} from 'react-toastify';

export const loadPartners = async () => {
    let partners = [];
    await getRequest('/api/partner').then(response => {
        if (response.status === 200) {
            partners = response.data.partners;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return partners;
};

export const loadLimitedPartners = async (limit) => {
    let partners = [];
    await getRequest('/api/partner/limit/' + limit).then(response => {
        if (response.status === 200) {
            partners = response.data.partners;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return partners;
};

export const loadPartner = async (id) => {
    let partner = null;
    await getRequest('/api/partner/' + id).then(response => {
        if (response.status === 200) {
            partner = response.data.partner;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return partner;
};

export const loadTShirts = async () => {
    let tShirts = [];
    await getRequest('/api/t-shirt').then(response => {
        if (response.status === 200) {
            tShirts = response.data.tShirts;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return tShirts;
};

export const loadAvailableTShirts = async () => {
    let tShirts = [];
    await getRequest('/api/t-shirt/available').then(response => {
        if (response.status === 200) {
            tShirts = response.data.tShirts;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return tShirts;
};

export const loadLimitedTShirts = async (limit) => {
    let tShirts = [];
    await getRequest('/api/t-shirt/limit/' + limit).then(response => {
        if (response.status === 200) {
            tShirts = response.data.tShirts;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return tShirts;
};

export const loadTShirt = async (id) => {
    let tShirt = null;
    await getRequest('/api/t-shirt/' + id).then(response => {
        if (response.status === 200) {
            tShirt = response.data.tShirt;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return tShirt;
};

export const loadGalleries = async () => {
    let gallery = [];
    await getRequest('/api/gallery').then(response => {
        if (response.status === 200) {
            gallery = response.data.gallery;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return gallery;
};

export const loadLimitedGalleries = async (limit) => {
    let gallery = [];
    await getRequest('/api/gallery/limit/' + limit).then(response => {
        if (response.status === 200) {
            gallery = response.data.gallery;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return gallery;
};

export const loadGallery = async (id) => {
    let gallery = null;
    await getRequest('/api/gallery/' + id).then(response => {
        if (response.status === 200) {
            gallery = response.data.gallery;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return gallery;
};

export const loadBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadPendingBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking/pending', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadCheckedInBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking/checkin', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadLimitedBookings = async (limit, token) => {
    let bookings = [];
    await getRequest('/api/booking/limit/' + limit, token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadBooking = async (id, token) => {
    let booking = null;
    await getRequest('/api/booking/' + id, token).then(response => {
        if (response.status === 200) {
            booking = response.data.booking;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return booking;
};

export const loadGroupBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking/group', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadPendingGroupBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking/group/pending', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadCheckedInGroupBookings = async (token) => {
    let bookings = [];
    await getRequest('/api/booking/group/checkin', token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadLimitedGroupBookings = async (limit, token) => {
    let bookings = [];
    await getRequest('/api/booking/group/limit/' + limit, token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadBookingDashboard = async (token) => {
    let data;
    await getRequest('/api/booking/admin/dashboard', token).then(response => {
        if (response.status === 200) {
            data = response.data;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return data;
};

export const loadUserBookings = async (userId, token) => {
    let bookings = [];
    await getRequest('/api/booking/user/' + userId, token).then(response => {
        if (response.status === 200) {
            bookings = response.data.bookings;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookings;
};

export const loadUsers = async (token) => {
    let users = [];
    await getRequest('/api/user', token).then(response => {
        if (response.status === 200) {
            users = response.data.users;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return users;
};

export const loadUser = async (id, token) => {
    let user = null;
    await getRequest('/api/user/' + id, token).then(response => {
        if (response.status === 200) {
            user = response.data.user;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return user;
};

export const loadRoles = async (token) => {
    let roles = [];
    await getRequest('/api/role', token).then(response => {
        if (response.status === 200) {
            roles = response.data.roles;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return roles;
};

export const loadBookingStatus = async (token) => {
    let bookingStatus = null;
    await getRequest('/api/booking-status', token).then(response => {
        if (response.status === 200) {
            bookingStatus = response.data.bookingStatus;
        } else {
            toast.error(response.data.message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    });
    return bookingStatus;
};
