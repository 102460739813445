import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

import logo from "../assets/images/image.png";
import dashboard from "../assets/images/dashboard.svg";
import tShirt from "../assets/images/t-shirt.svg";

import {deleteUser, getUser} from "../auth/Auth";
import {loadBookingStatus} from "../common/Common";
import {toast} from "react-toastify";
import {postRequest} from "../routes/Routes";

const Sidebar = () => {
    const [user, setUser] = useState(null),
        [bookingStatus, setBookingStatus] = useState(null),
        [showIndividualBookings, setShowIndividualBookings] = useState(false),
        [showGroupBookings, setShowGroupBookings] = useState(false);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
        loadBookingStatus().then((data) => {
            setBookingStatus(data);
        }).catch(() => {
            setBookingStatus(null);
        });
    }, []);

    const handleLogout = () => {
        deleteUser().then(() => {
            window.location.href = '/';
        });
    };

    const handleBookingStatus = (e) => {
        e.preventDefault();
        let values = {
            active: !bookingStatus.active,
            token: user.token,
            endpoint: '/api/booking-status'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setBookingStatus({...response.data.bookingStatus});
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    };

    if (user) {
        return (
            <div className="sidebar">
                {/* Add navigation links */}
                <div className="logo-nav">
                    <img src={logo}/>
                </div>
                <nav>
                    <ul type="none">
                        {user.role.name === "ADMIN" &&
                        <li>
                            <NavLink end to="/admin/dashboard" className="nav-link">
                                <img src={dashboard} alt="Dashboard"/>
                                Dashboard
                            </NavLink>
                        </li>
                        }

                        <li>
                            <NavLink
                                to="/admin/dashboard/individualBooking"
                                className="nav-link"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowIndividualBookings(!showIndividualBookings)
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                >
                                    <path
                                        d="M7.09325 6.34067C7.03491 6.33484 6.96491 6.33484 6.90075 6.34067C5.51241 6.294 4.40991 5.1565 4.40991 3.7565C4.40991 2.32734 5.56491 1.1665 6.99991 1.1665C8.42908 1.1665 9.58991 2.32734 9.58991 3.7565C9.58408 5.1565 8.48158 6.294 7.09325 6.34067Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M4.17667 8.4935C2.765 9.4385 2.765 10.9785 4.17667 11.9177C5.78084 12.991 8.41167 12.991 10.0158 11.9177C11.4275 10.9727 11.4275 9.43266 10.0158 8.4935C8.4175 7.426 5.78667 7.426 4.17667 8.4935Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                Individual Booking
                                {showIndividualBookings ?
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        style={{marginRight: 0, marginLeft: 10}}
                                    >
                                        <path
                                            d="M11.9465 10.5469H7.79317H4.05317C3.41317 10.5469 3.09317 9.77354 3.5465 9.32021L6.99983 5.86688C7.55317 5.31354 8.45317 5.31354 9.0065 5.86688L10.3198 7.18021L12.4598 9.32021C12.9065 9.77354 12.5865 10.5469 11.9465 10.5469Z"
                                            fill="#767676"
                                        />
                                    </svg>
                                    : <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        style={{marginRight: 0, marginLeft: 10}}
                                    >
                                        <path
                                            d="M11.9467 5.45312H7.79341H4.05341C3.41341 5.45312 3.09341 6.22646 3.54674 6.67979L7.00008 10.1331C7.55341 10.6865 8.45341 10.6865 9.00674 10.1331L10.3201 8.81979L12.4601 6.67979C12.9067 6.22646 12.5867 5.45312 11.9467 5.45312Z"
                                            fill="#767676"
                                        />
                                    </svg>
                                }
                            </NavLink>
                        </li>

                        {showIndividualBookings &&
                        <>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/individualBooking"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Confirmed
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/individualBooking/pending"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Pending / Failed
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/individualBooking/checkin"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Checked In
                                </NavLink>
                            </li>
                        </>
                        }

                        <li>
                            <NavLink to="/admin/dashboard/groupBooking" className="nav-link"
                                     onClick={(event) => {
                                         event.preventDefault();
                                         setShowGroupBookings(!showGroupBookings)
                                     }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                >
                                    <path
                                        d="M10.4999 4.17699C10.4649 4.17116 10.424 4.17116 10.389 4.17699C9.58405 4.14783 8.94238 3.48866 8.94238 2.67199C8.94238 1.83783 9.61321 1.16699 10.4474 1.16699C11.2815 1.16699 11.9524 1.84366 11.9524 2.67199C11.9465 3.48866 11.3049 4.14783 10.4999 4.17699Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9.89909 8.42375C10.6983 8.55792 11.5791 8.41792 12.1974 8.00375C13.0199 7.45542 13.0199 6.55709 12.1974 6.00875C11.5733 5.59459 10.6808 5.45458 9.88159 5.59458"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M3.48253 4.17699C3.51753 4.17116 3.55836 4.17116 3.59336 4.17699C4.39836 4.14783 5.04003 3.48866 5.04003 2.67199C5.04003 1.83783 4.3692 1.16699 3.53503 1.16699C2.70086 1.16699 2.03003 1.84366 2.03003 2.67199C2.03586 3.48866 2.67753 4.14783 3.48253 4.17699Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M4.08342 8.42375C3.28425 8.55792 2.40342 8.41792 1.78509 8.00375C0.962588 7.45542 0.962588 6.55709 1.78509 6.00875C2.40925 5.59459 3.30175 5.45458 4.10092 5.59458"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6.99988 8.53442C6.96488 8.52858 6.92405 8.52858 6.88905 8.53442C6.08405 8.50525 5.44238 7.84608 5.44238 7.02942C5.44238 6.19525 6.11322 5.52441 6.94738 5.52441C7.78155 5.52441 8.45238 6.20108 8.45238 7.02942C8.44655 7.84608 7.80488 8.51108 6.99988 8.53442Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M5.30242 10.372C4.47992 10.9203 4.47992 11.8186 5.30242 12.367C6.23576 12.9911 7.76409 12.9911 8.69742 12.367C9.51992 11.8186 9.51992 10.9203 8.69742 10.372C7.76992 9.75362 6.23576 9.75362 5.30242 10.372Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                Group Booking
                                {showGroupBookings ?
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        style={{marginRight: 0, marginLeft: 10}}
                                    >
                                        <path
                                            d="M11.9465 10.5469H7.79317H4.05317C3.41317 10.5469 3.09317 9.77354 3.5465 9.32021L6.99983 5.86688C7.55317 5.31354 8.45317 5.31354 9.0065 5.86688L10.3198 7.18021L12.4598 9.32021C12.9065 9.77354 12.5865 10.5469 11.9465 10.5469Z"
                                            fill="#767676"
                                        />
                                    </svg>
                                    : <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        style={{marginRight: 0, marginLeft: 10}}
                                    >
                                        <path
                                            d="M11.9467 5.45312H7.79341H4.05341C3.41341 5.45312 3.09341 6.22646 3.54674 6.67979L7.00008 10.1331C7.55341 10.6865 8.45341 10.6865 9.00674 10.1331L10.3201 8.81979L12.4601 6.67979C12.9067 6.22646 12.5867 5.45312 11.9467 5.45312Z"
                                            fill="#767676"
                                        />
                                    </svg>
                                }
                            </NavLink>
                        </li>

                        {showGroupBookings &&
                        <>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/groupBooking"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Confirmed
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/groupBooking/pending"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Pending / Failed
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    end
                                    to="/admin/dashboard/groupBooking/checkin"
                                    className="nav-link"
                                >
                                    &emsp;&emsp; Checked In
                                </NavLink>
                            </li>
                        </>
                        }

                        {user.role.name === "ADMIN" &&
                        [<li key="partners">
                            <NavLink to="/admin/dashboard/partners" className="nav-link">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                >
                                    <path
                                        d="M9.89331 3.59961C11.06 4.41044 11.865 5.69961 12.0283 7.18711"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M2.03589 7.21543C2.18756 5.73376 2.98089 4.4446 4.13589 3.62793"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M4.77759 12.2148C5.45425 12.559 6.22426 12.7515 7.03509 12.7515C7.81675 12.7515 8.55175 12.5765 9.21092 12.2557"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M7.035 4.49138C7.93062 4.49138 8.65666 3.76534 8.65666 2.86971C8.65666 1.97409 7.93062 1.24805 7.035 1.24805C6.13938 1.24805 5.41333 1.97409 5.41333 2.86971C5.41333 3.76534 6.13938 4.49138 7.035 4.49138Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M2.81747 11.6203C3.71309 11.6203 4.43913 10.8942 4.43913 9.99862C4.43913 9.103 3.71309 8.37695 2.81747 8.37695C1.92185 8.37695 1.1958 9.103 1.1958 9.99862C1.1958 10.8942 1.92185 11.6203 2.81747 11.6203Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M11.1825 11.6203C12.0781 11.6203 12.8041 10.8942 12.8041 9.99862C12.8041 9.103 12.0781 8.37695 11.1825 8.37695C10.2868 8.37695 9.56079 9.103 9.56079 9.99862C9.56079 10.8942 10.2868 11.6203 11.1825 11.6203Z"
                                        stroke="#767676"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                Partners
                            </NavLink>
                        </li>,
                            <li key="gallery">
                                <NavLink to="/admin/dashboard/gallery" className="nav-link">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M5.25008 12.8337H8.75008C11.6667 12.8337 12.8334 11.667 12.8334 8.75033V5.25033C12.8334 2.33366 11.6667 1.16699 8.75008 1.16699H5.25008C2.33341 1.16699 1.16675 2.33366 1.16675 5.25033V8.75033C1.16675 11.667 2.33341 12.8337 5.25008 12.8337Z"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7 1.16699V12.8337"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M1.16675 5.54199H7.00008"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7 8.45801H12.8333"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    Gallery
                                </NavLink>
                            </li>,
                            <li key="t-shirt">
                                <NavLink to="/admin/dashboard/tshirt/overview" className="nav-link">
                                    <img src={tShirt} alt="T-Shirt"/>
                                    T-shirt
                                </NavLink>
                            </li>,
                            <li key="user">
                                <NavLink to="/admin/dashboard/users" className="nav-link">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M7.09325 6.34067C7.03491 6.33484 6.96491 6.33484 6.90075 6.34067C5.51241 6.294 4.40991 5.1565 4.40991 3.7565C4.40991 2.32734 5.56491 1.1665 6.99991 1.1665C8.42908 1.1665 9.58991 2.32734 9.58991 3.7565C9.58408 5.1565 8.48158 6.294 7.09325 6.34067Z"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M4.17667 8.4935C2.765 9.4385 2.765 10.9785 4.17667 11.9177C5.78084 12.991 8.41167 12.991 10.0158 11.9177C11.4275 10.9727 11.4275 9.43266 10.0158 8.4935C8.4175 7.426 5.78667 7.426 4.17667 8.4935Z"
                                            stroke="#767676"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    User
                                </NavLink>
                            </li>,
                            bookingStatus &&
                            <li key="disableBooking">
                                <NavLink end to="/" className="nav-link"
                                         onClick={(event) => handleBookingStatus(event)}>
                                    <img src={dashboard} alt="Dashboard"/>
                                    {bookingStatus.active ? "Disable" : "Enable"} Bookings
                                </NavLink>
                            </li>
                        ]}
                    </ul>
                </nav>

                {/* Add Logout link */}
                <div className="logout-link" onClick={() => handleLogout()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                    >
                        <path
                            d="M8.80835 4.40965C8.62752 2.30965 7.54835 1.45215 5.18585 1.45215H5.11002C2.50252 1.45215 1.45835 2.49632 1.45835 5.10382V8.90715C1.45835 11.5146 2.50252 12.5588 5.11002 12.5588H5.18585C7.53085 12.5588 8.61002 11.713 8.80252 9.64798"
                            stroke="#767676"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.25009 7H11.8884"
                            stroke="#767676"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M10.5876 5.0459L12.5417 7.00007L10.5876 8.95423"
                            stroke="#767676"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Logout
                </div>
            </div>
        );
    }
}

export default Sidebar;
