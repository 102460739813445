// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {loadAvailableTShirts, loadBooking} from "../common/Common";
import {getUser} from "../auth/Auth";

const AddGroupRecAdmin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(true),
        [tShirts, setTShirts] = useState([]),
        [bookingId] = useState(decodeURI(window.location.href.split('/').at(-1)));

    const [formData, setFormData] = useState({
        comName: "",
        conPerson: "",
        conNumber: "",
        email: "",
        grpSize: "",
        amount: "",
        tShirts: [],
        memberCount: 0
    });

    useEffect(() => {
        loadAvailableTShirts().then(data => {
            setTShirts(data);
            setLoadingContent(false);
        }).catch(() => null);
        getUser().then((data) => {
            setUser(data);
            if (bookingId !== 'add') {
                setLoadingContent(true);
                loadBooking(bookingId, data.token).then(data => {
                    const tempFormData = {
                        comName: data.full_name,
                        conPerson: data.person_name,
                        conNumber: data.phone,
                        email: data.email,
                        amount: data.donation,
                        grpSize: data.group_category,
                        tShirts: data.booking_t_shirts,
                        memberCount: 0,
                        id: data.id,
                        status: data.status,
                        payment: data.payment,
                        checkin: data.checkin,
                        checkin_count: data.checkin_count,
                        checked_in_by: data.checked_in_by
                    }
                    data.booking_t_shirts.forEach((tShirt) => {
                        tempFormData.memberCount += tShirt.quantity;
                        if (tShirt.t_shirt_id) {
                            tempFormData[tShirt.t_shirt.size + "UsedCount"] = tShirt.quantity;
                            tempFormData[tShirt.t_shirt.size + "Count"] = tShirt.quantity;
                        } else {
                            tempFormData["noTshirtCount"] = tShirt.quantity;
                        }
                    });
                    setFormData(tempFormData);
                    setLoadingContent(false);
                }).catch(() => null);
            } else {
                const booking = JSON.parse(sessionStorage.getItem('groupBooking'));
                const tempFormData = {
                    comName: booking.full_name,
                    conPerson: booking.person_name,
                    conNumber: booking.phone,
                    email: booking.email,
                    amount: booking.donation,
                    grpSize: booking.group_category,
                    tShirts: booking.booking_t_shirts,
                    memberCount: 0,
                }
                booking.booking_t_shirts.forEach((tShirt) => {
                    tempFormData.memberCount += tShirt.quantity;
                    if (tShirt.t_shirt_id) {
                        tempFormData[tShirt.t_shirt.size + "Count"] = tShirt.quantity;
                    } else {
                        tempFormData["noTshirtCount"] = tShirt.quantity;
                    }
                });
                setFormData(tempFormData);
            }
        }).catch(() => null);
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTShirtChange = (e, tShirt) => {
        const {name, value} = e.target;
        let tShirts = formData.tShirts,
            tShirtIndex = tShirts.findIndex(function (item) {
                return (item.t_shirt_id === (tShirt ? tShirt.id : null));
            });
        if (tShirtIndex >= 0) {
            if (value === '0') {
                formData.memberCount -= tShirts[tShirtIndex].quantity;
                tShirts.splice(tShirtIndex, 1);
            } else {
                formData.memberCount += (Number(value) - tShirts[tShirtIndex].quantity);
                tShirts[tShirtIndex].quantity = Number(value);
            }
        } else {
            formData.memberCount += Number(value);
            if (tShirt) {
                tShirts.push({
                    t_shirt: tShirt,
                    t_shirt_id: tShirt.id,
                    quantity: Number(value)
                })
            } else {
                tShirts.push({
                    t_shirt_id: null,
                    quantity: Number(value)
                })
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            tShirts: tShirts,
            [name]: value
        }));
    };

    const [showText, setShowText] = useState(false);

    const toggleText = () => {
        setShowText(!showText);
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (formData.memberCount >= 5) {
            if (formData.memberCount <= 30) {
                let values = {
                    full_name: formData.comName,
                    person_name: formData.conPerson,
                    email: formData.email,
                    phone: formData.conNumber,
                    donation: formData.amount,
                    group_category: formData.grpSize,
                    booking_t_shirts: formData.tShirts,
                    member_count: formData.memberCount,
                    id: formData.hasOwnProperty("id") ? formData.id : null
                };
                sessionStorage.setItem('groupBooking', JSON.stringify(values));
                setLoading(false);
                navigate('/admin/dashboard/groupBooking/checkout');
            } else {
                toast.error("The maximum number of members allowed is 30", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            }
        } else {
            toast.error("There should be a minimum of 5 members", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate(formData.hasOwnProperty("id") && formData.status !== "Confirmed" ? '/admin/dashboard/groupBooking/pending' : formData.hasOwnProperty("id") && formData.checkin ? '/admin/dashboard/groupBooking/checkin' : '/admin/dashboard/groupBooking');
    };

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Group Booking</h1>
                            <span>Add/ Remove / Edit Group Booking</span>
                        </div>
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="frm-row single-row">
                                    <div className="one-row">
                                        <label htmlFor="comName">
                                            Organization / Company / Group Name *
                                        </label>
                                        <input
                                            type="text"
                                            id="comName"
                                            name="comName"
                                            value={formData.comName}
                                            onChange={handleChange}
                                            placeholder="Yellawt Team"
                                            required
                                        />
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="conPerson">Name of Contact Person *</label>
                                        <input
                                            type="text"
                                            id="conPerson"
                                            name="conPerson"
                                            value={formData.conPerson}
                                            onChange={handleChange}
                                            placeholder="John Thonmpson"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="frm-row single-row">
                                    <div className="one-row">
                                        <label htmlFor="conNumber">Contact Number *</label>
                                        <span>
                    (The Phone Number of the contact person in the group)
                  </span>
                                        <input
                                            type="tel"
                                            id="conNumber"
                                            name="conNumber"
                                            value={formData.conNumber}
                                            onChange={handleChange}
                                            placeholder="+94 77 222 3333"
                                            required
                                        />
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="email">Email Address *</label>
                                        <span>
                    (This is where you would get your receipt to, so type
                    carefully)
                  </span>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="user@email.com"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="showHide-container">
                                    <button type="button" onClick={toggleText} className="toggleButton">
                                        T-Shirt Size Details
                                        {showText ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M11.9465 10.5469H7.79317H4.05317C3.41317 10.5469 3.09317 9.77354 3.5465 9.32021L6.99983 5.86688C7.55317 5.31354 8.45317 5.31354 9.0065 5.86688L10.3198 7.18021L12.4598 9.32021C12.9065 9.77354 12.5865 10.5469 11.9465 10.5469Z"
                                                    fill="#A83FE5"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                            >
                                                <path
                                                    d="M11.9467 5.45312H7.79341H4.05341C3.41341 5.45312 3.09341 6.22646 3.54674 6.67979L7.00008 10.1331C7.55341 10.6865 8.45341 10.6865 9.00674 10.1331L10.3201 8.81979L12.4601 6.67979C12.9067 6.22646 12.5867 5.45312 11.9467 5.45312Z"
                                                    fill="#A83FE5"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                    {showText && (
                                        <div className="contentToToggle">
                                            <div className="tg-row">
                                                <div className="top-rw">
                                                    <span className="lft-tg">T-shirt Size</span>
                                                    <span className="rgt-tg">No T-shirt</span>
                                                </div>
                                                <div className="top-rw">
                                                    <span className="lft-tg">Quantity</span>
                                                    <input
                                                        type="number"
                                                        id="noTshirtCount"
                                                        name="noTshirtCount"
                                                        value={formData.noTshirtCount}
                                                        onChange={(event) => handleTShirtChange(event, null)}
                                                        placeholder="0"
                                                        min={0}
                                                    />
                                                </div>
                                            </div>
                                            {tShirts.map((item, index) => (
                                                <div className="tg-row" key={index}>
                                                    <div className="top-rw">
                                                        <span className="lft-tg">T-shirt Size</span>
                                                        <span className="rgt-tg">{item.description} ({item.size})</span>
                                                    </div>
                                                    <div className="top-rw">
                                                        <span className="lft-tg">Quantity</span>
                                                        <input
                                                            type="number"
                                                            id={item.size + "Count"}
                                                            name={item.size + "Count"}
                                                            value={formData[item.size + "Count"]}
                                                            onChange={(event) => handleTShirtChange(event, item)}
                                                            placeholder="0"
                                                            min={0}
                                                            max={formData.hasOwnProperty("id") && formData.hasOwnProperty(item.size + "UsedCount") ? item.remaining + Number(formData[item.size + "UsedCount"]) : item.remaining}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="frm-row single-row halfRow">
                                    <div className="one-row">
                                        <label htmlFor="grpSize">Group Category *</label>
                                        <span>
                    (Choose a Group Category)
                  </span>
                                        <select
                                            id="grpSize"
                                            name="grpSize"
                                            value={formData.grpSize}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Please Select one</option>
                                            <option value="Private">Private</option>
                                            <option value="Company">Company</option>
                                        </select>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="amount">Donation amount *</label>
                                        <span>
                    (The minimum donation amount per person is LKR 1600)
                  </span>
                                        <input
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleChange}
                                            placeholder="LKR 1600"
                                            required
                                            min={formData.memberCount * 1600}
                                        />
                                    </div>
                                </div>
                                {formData.hasOwnProperty("id") && formData.status !== "Confirmed" &&
                                <div className="frm-row single-row halfRow">
                                    <div className="one-row">
                                        <label htmlFor="grpSize">Status</label>
                                        <span>
                                            {formData.status}
                                        </span>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="amount">IPG Comment</label>
                                        <span>
                                            {formData.payment ? formData.payment.comment : ""}
                                        </span>
                                    </div>
                                </div>
                                }
                                <div className="termsandCondition">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="agreeTerms"
                                            name="agreeTerms"
                                            checked={formData.agreeTerms}
                                            onChange={handleChange}
                                            required
                                        />
                                        <span>
                    I, the participant, on behalf of all the participants
                    registering with this registration , intending to be legally
                    bound and hereby waive or release any and all right and
                    claims for damages or injuries that I may have against the
                    organizers (The Rotaract Club of Colombo Uptown) and all the
                    official volunteers assisting with the event for any and all
                    injuries to me or my personal property. This release
                    includes all...
                  </span>
                                    </label>
                                </div>
                                <div className="buttin-set-sub">
                                    <button type="button" className="onFormCancle" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="onFormSubmit">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AddGroupRecAdmin;
