// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CSVLink} from "react-csv";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {getUser} from "../auth/Auth";
import {
    loadBookings,
    loadCheckedInBookings,
    loadPendingBookings,
    loadTShirts,
    loadUser,
    loadUserBookings
} from "../common/Common";
import {deleteRequest, getRequest} from "../routes/Routes";
import {toast} from "react-toastify";

const AdminIndividualBook = ({userBooking}) => {
    const navigate = useNavigate(),
        [userId, setUserId] = useState(null),
        [bookingUser, setBookingUser] = useState(null),
        [url, setUrl] = useState(decodeURI(window.location.href.split('/').at(-1)));

    const handleAdd = () => {
        navigate("/admin/dashboard/individualBooking/add");
    };

    const [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(true),
        [loadingDownload, setLoadingDownload] = useState(false),
        [loadingConfirm, setLoadingConfirm] = useState(false),
        [tShirts, setTShirts] = useState([]),
        [sampleData, setSampleData] = useState([]),
        [searchName, setSearchName] = useState(""),
        [selectedSize, setSelectedSize] = useState(""),
        [filteredItems, setFilteredItems] = useState([]),
        [csvData, setCsvData] = useState([]),
        [bookingDashboard, setBookingDashboard] = useState({
            individualDonations: 0.00,
        });

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const itemsPerPage = 20;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        const tempUrl = decodeURI(window.location.href.split('/').at(-1));
        setLoadingContent(true);
        setBookingUser(null);
        getUser().then((data) => {
            let tempUser = data;
            setUser(data);
            if (userBooking) {
                loadUser(tempUrl, data.token).then(data => {
                    setBookingUser(data);
                }).catch(() => null);
                loadUserBookings(tempUrl, data.token).then(data => {
                    setSampleData(data);
                    setFilteredItems(data);
                    setLoadingContent(false);
                }).catch(() => null);
                setUserId(tempUrl);
            } else if (tempUrl === "pending") {
                loadPendingBookings(data.token).then(data => {
                    setSampleData(data);
                    setFilteredItems(data);
                    setLoadingContent(false);
                    if (tempUser.role.name !== "ADMIN") {
                        getDashboardData(data);
                    }
                }).catch(() => null);
            } else if (tempUrl === "checkin") {
                loadCheckedInBookings(data.token).then(data => {
                    setSampleData(data);
                    setFilteredItems(data);
                    setLoadingContent(false);
                    if (tempUser.role.name !== "ADMIN") {
                        getDashboardData(data);
                    }
                }).catch(() => null);
            } else {
                loadBookings(data.token).then(data => {
                    setSampleData(data);
                    setFilteredItems(data);
                    setLoadingContent(false);
                    if (tempUser.role.name !== "ADMIN") {
                        getDashboardData(data);
                    }
                }).catch(() => null);
            }
        }).catch(() => {
            setUser(null);
        });
        loadTShirts().then(data => {
            setTShirts(data);
        }).catch(() => null);
        setUrl(tempUrl);
    }, [decodeURI(window.location.href.split('/').at(-1))]);

    const getDashboardData = (bookings) => {
        let individualDonations = 0.00;
        bookings.forEach((booking) => {
            individualDonations += booking.donation;
        });
        setBookingDashboard((data) => ({
            ...data,
            individualDonations: individualDonations
        }));
    }

    useEffect(() => {
        // Update selectedRows when switching pages
        setSelectedRows([]);
    }, [currentPage]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
        } else {
            setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((id) => id !== id)
            );
        }
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            setSelectedRows(filteredItems.map((item) => item.id));
        } else {
            setSelectedRows([]);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleSearch = () => {
        const newFilteredItems = sampleData.filter((item) => {
            const nameMatch = item.full_name
                .toLowerCase()
                .includes(searchName.toLowerCase());
            const sizeMatch = selectedSize ? item.t_shirt_id === (selectedSize === "No T-Shirt" ? null : JSON.parse(selectedSize).id) : true;
            return nameMatch && sizeMatch;
        });
        setFilteredItems(newFilteredItems);
    };

    useEffect(() => {
        // Update filtered items when search criteria change
        handleSearch();
    }, [searchName, selectedSize]);

    const handleDeleteRows = () => {
        setLoading(true);
        selectedRows.forEach((booking, index) => {
            deleteRequest('/api/booking/' + booking, user.token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        if (selectedRows.length === index + 1) {
                            setSampleData(values => values.filter(function (booking) {
                                return !selectedRows.includes(booking.id);
                            }));
                            setFilteredItems(values => values.filter(function (booking) {
                                return !selectedRows.includes(booking.id);
                            }));
                            setSelectedRows([]);
                            toast.success('Successfully deleted Bookings', {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error('An error occurred!', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            });
        });
    };

    const handleConfirmRows = () => {
        setLoadingConfirm(true);
        selectedRows.forEach((booking, index) => {
            getRequest('/api/booking/confirm/' + booking, user.token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        if (selectedRows.length === index + 1) {
                            setSampleData(values => values.filter(function (booking) {
                                return !selectedRows.includes(booking.id);
                            }));
                            setFilteredItems(values => values.filter(function (booking) {
                                return !selectedRows.includes(booking.id);
                            }));
                            setSelectedRows([]);
                            toast.success('Successfully Confirmed Bookings', {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error('An error occurred!', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoadingConfirm(false);
            });
        });
    };

    const download = (event, done) => {
        setLoadingDownload(true);
        let tempCsvData = userBooking ? [
            ["Booking Reference", "Full Name", "Email", "Date of Birth", "Contact Person", "Contact Number", "No T-Shirt", "Member Count", "Payment Type", "Status", "Date", "Registration Fee", "Extra Donation", "Total Donation", "Created By"],
        ] : [
            ["Booking Reference", "Full Name", "Email", "Date of Birth", "Contact Number", "T-Shirt Size", "Payment Type", "Status", "Date", "Registration Fee", "Extra Donation", "Total Donation", "Created By"],
        ];
        let index;
        if (url === "pending") {
            index = userBooking ? 9 : 7;
            tempCsvData[0] = [
                ...tempCsvData[0].slice(0, index),
                "IPG Comment",
                ...tempCsvData[0].slice(index)
            ]
        } else if (url === "checkin") {
            tempCsvData[0].push("Checked In By");
        }
        if (userBooking) {
            tShirts.forEach((tShirt, index) => {
                tempCsvData[0] = [
                    ...tempCsvData[0].slice(0, 7 + index),
                    tShirt.description,
                    ...tempCsvData[0].slice(7 + index)
                ]
            });
            sampleData.forEach((booking) => {
                tempCsvData.push([
                    booking.reference,
                    booking.full_name,
                    booking.email,
                    booking.date_of_birth,
                    booking.person_name,
                    booking.phone,
                    0,
                    0,
                    booking.payment_type,
                    booking.status,
                    (new Date(booking.created_at)).toLocaleString(),
                    "LKR 1600.00",
                    "LKR " + (booking.donation - 1600),
                    "LKR " + booking.donation,
                    booking.user ? booking.user.name : "",
                ]);
                tShirts.forEach((tShirt, index) => {
                    tempCsvData[tempCsvData.length - 1] = [
                        ...tempCsvData[tempCsvData.length - 1].slice(0, 7 + index),
                        0,
                        ...tempCsvData[tempCsvData.length - 1].slice(7 + index)
                    ]
                });
                if (booking.status !== "Confirmed") {
                    index = tempCsvData[0].indexOf("IPG Comment");
                    tempCsvData[tempCsvData.length - 1] = [
                        ...tempCsvData[tempCsvData.length - 1].slice(0, index),
                        booking.payment ? booking.payment.comment : "",
                        ...tempCsvData[tempCsvData.length - 1].slice(index)
                    ]
                }
                let memberCount = booking.is_group ? 0 : 1;
                if (booking.is_group) {
                    booking.booking_t_shirts.forEach((tShirt) => {
                        memberCount += tShirt.quantity;
                        if (tShirt.t_shirt_id) {
                            index = tempCsvData[0].indexOf(tShirt.t_shirt.description);
                            tempCsvData[tempCsvData.length - 1][index] = tShirt.quantity;
                        } else {
                            tempCsvData[tempCsvData.length - 1][6] = tShirt.quantity;
                        }
                    });
                } else {
                    if (booking.t_shirt) {
                        index = tempCsvData[0].indexOf(booking.t_shirt.description);
                        tempCsvData[tempCsvData.length - 1][index] = 1;
                    } else {
                        tempCsvData[tempCsvData.length - 1][5] = 1;
                    }
                }
                tempCsvData[tempCsvData.length - 1][tempCsvData[0].indexOf("Member Count")] = memberCount;
                tempCsvData[tempCsvData.length - 1][tempCsvData[0].indexOf("Registration Fee")] = "LKR" + (1600 * memberCount);
                tempCsvData[tempCsvData.length - 1][tempCsvData[0].indexOf("Extra Donation")] = "LKR " + (booking.donation - (1600 * memberCount));
            });
        } else {
            sampleData.forEach((booking) => {
                tempCsvData.push([
                    booking.reference,
                    booking.full_name,
                    booking.email,
                    booking.date_of_birth,
                    booking.phone,
                    booking.t_shirt ? booking.t_shirt.description : "No T-Shirt",
                    booking.payment_type,
                    booking.status,
                    (new Date(booking.created_at)).toLocaleString(),
                    "LKR 1600.00",
                    "LKR " + (booking.donation - 1600),
                    "LKR " + booking.donation,
                    booking.user ? booking.user.name : "",
                ]);
                if (booking.status !== "Confirmed") {
                    tempCsvData[tempCsvData.length - 1] = [
                        ...tempCsvData[tempCsvData.length - 1].slice(0, index),
                        booking.payment ? booking.payment.comment : "",
                        ...tempCsvData[tempCsvData.length - 1].slice(index)
                    ]
                }
                if (url === "checkin") {
                    tempCsvData[tempCsvData.length - 1].push(booking.checked_in_by.name);
                }
            });
        }
        setCsvData(tempCsvData);
        setLoadingDownload(false);
        done(true);
    }

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>{bookingUser ? "Bookings By " + bookingUser.name : "Individual Booking"}</h1>
                            <span>Add/ Remove / Edit Individual Booking</span>
                        </div>
                        {/* add remove buttons */}
                        <div className="addRemBtnAdmiin">
                            {user.role.name !== "ADMIN" &&
                            <div className="cardRow" style={{marginTop: 0}}>
                                <div className="singleCard whiteCard">
                                    <div className="topRow">
                                        <div className="frsLn">
                                    <span
                                        id="totParticipant">Rs. {bookingDashboard.individualDonations}</span>
                                            <div className="txtCardIn">Total Individual Donations</div>
                                        </div>
                                        <div className="firLinIco">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="17"
                                                viewBox="0 0 18 17"
                                                fill="none"
                                            >
                                                <path
                                                    d="M8.67107 10.8339C9.20317 11.366 10.0405 11.3866 10.5478 10.8793L11.5832 9.84396C12.0245 9.40261 12.008 8.6684 11.546 8.20642C11.0428 7.7032 10.6468 7.74444 10.2055 7.95481L7.96573 9.03963C7.52438 9.24999 7.1284 9.29124 6.62517 8.78802C6.1632 8.32604 6.1467 7.59183 6.58805 7.15047L7.62337 6.11515C8.13072 5.6078 8.96805 5.62843 9.50015 6.16052"
                                                    stroke="#292D32"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M6.60791 6.0249L11.5577 10.9746"
                                                    stroke="#292D32"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M13.2076 12.6248C15.4857 10.3467 15.4857 6.65327 13.2076 4.37521C10.9296 2.09715 7.2361 2.09715 4.95804 4.37521C2.67998 6.65327 2.67998 10.3467 4.95804 12.6248C7.2361 14.9028 10.9296 14.9028 13.2076 12.6248Z"
                                                    stroke="#292D32"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M3.1665 14.8325H14.8332"
                                                    stroke="#292D32"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="flex-btn">
                                {user.role.name === "ADMIN" &&
                                <>
                                    <button type="button" className="addRemBtn reRec">
                                        {loadingDownload ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        <CSVLink
                                            data={csvData}
                                            asyncOnClick={true}
                                            filename={bookingUser ? bookingUser.name + ".csv" : url === "pending" ? "pending-bookings.csv" : url === "checkin" ? "checked-in-bookings.csv" : "bookings.csv"}
                                            style={{textDecoration: "none", color: "inherit"}}
                                            onClick={download}>
                                            Download
                                        </CSVLink>
                                    </button>
                                    <button type="button" className="addRemBtn reRec" onClick={handleDeleteRows}
                                            disabled={selectedRows.length === 0}>
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Delete Record
                                    </button>
                                    {url === "pending" &&
                                    <button type="button" className="addRemBtn reRec" onClick={handleConfirmRows}
                                            disabled={selectedRows.length === 0}>
                                        {loadingConfirm ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Confirm
                                    </button>
                                    }
                                </>
                                }
                                {!userBooking &&
                                <button
                                    type="button"
                                    className="addRemBtn addRec"
                                    onClick={handleAdd}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.5 7H10.5"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7 10.5V3.5"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    Add Record
                                </button>
                                }
                            </div>
                        </div>
                        {/* Search Box */}
                        <div className="search-box">
                            <span>What are you looking for?</span>
                            <div className="flex-search">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Search by name"
                                        value={searchName}
                                        onChange={(e) => setSearchName(e.target.value)}
                                    />
                                </div>
                                <div className="flex-search-ico">
                                    <select
                                        value={selectedSize}
                                        onChange={(e) => setSelectedSize(e.target.value)}
                                    >
                                        <option value="">All Sizes</option>
                                        <option value="No T-Shirt">No T-Shirt</option>
                                        {tShirts.map((item, index) => (
                                            <option value={JSON.stringify(item)} key={index}>{item.description}</option>
                                        ))}
                                    </select>
                                    <span className="search-icon" onClick={handleSearch}>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                  >
                    <path
                        d="M6.41663 11.667C9.31612 11.667 11.6666 9.31649 11.6666 6.41699C11.6666 3.5175 9.31612 1.16699 6.41663 1.16699C3.51713 1.16699 1.16663 3.5175 1.16663 6.41699C1.16663 9.31649 3.51713 11.667 6.41663 11.667Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M11.0424 12.0691C11.3516 13.0025 12.0574 13.0958 12.5999 12.2791C13.0957 11.5325 12.7691 10.92 11.8707 10.92C11.2057 10.9141 10.8324 11.4333 11.0424 12.0691Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                  </svg>
                </span>
                                </div>
                            </div>
                        </div>
                        {/* pagination */}
                        <table>
                            <thead className="thead">
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={
                                            selectedRows.length === filteredItems.length &&
                                            selectedRows.length > 0
                                        }
                                        onChange={handleSelectAllChange}
                                        style={{marginRight: 22 + "px"}}
                                    />
                                    BookingID
                                </th>
                                <th>Full Name</th>
                                {userBooking && <th>Contact Person</th>}
                                <th>Date of Birth</th>
                                <th>Contact Number</th>
                                {!userBooking && <th>T-shirt Size</th>}
                                <th>Payment Type</th>
                                <th>Date</th>
                                {url === "pending" && <th>Status</th>}
                                {url === "checkin" && <th>Checked In By</th>}
                                {user.role.name === "ADMIN" && <th></th>}
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(item.id)}
                                            onChange={(e) => handleCheckboxChange(e, item.id)}
                                            style={{marginRight: 22 + "px"}}
                                        />
                                        {item.reference}
                                    </td>
                                    <td>{item.full_name}</td>
                                    {userBooking && <td>{item.person_name}</td>}
                                    <td>{item.date_of_birth}</td>
                                    <td>{item.phone}</td>
                                    {!userBooking && <td>{item.t_shirt ? item.t_shirt.description : "No T-Shirt"}</td>}
                                    <td>{item.payment_type}</td>
                                    <td>{(new Date(item.created_at)).toLocaleString()}</td>
                                    {url === "pending" && <td>{item.status}</td>}
                                    {url === "checkin" && <td>{item.checked_in_by.name}</td>}
                                    {user.role.name === "ADMIN" &&
                                    <td>
                                        <button id="editdata" className="editBtn"
                                                onClick={() => navigate((item.is_group ? "/admin/dashboard/groupBooking/edit/" : "/admin/dashboard/individualBooking/edit/") + item.id)}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M6.41663 1.16699H5.24996C2.33329 1.16699 1.16663 2.33366 1.16663 5.25033V8.75033C1.16663 11.667 2.33329 12.8337 5.24996 12.8337H8.74996C11.6666 12.8337 12.8333 11.667 12.8333 8.75033V7.58366"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.35672 1.76206L4.76005 6.35872C4.58505 6.53372 4.41005 6.87789 4.37505 7.12872L4.12422 8.88456C4.03088 9.52039 4.48005 9.96372 5.11588 9.87622L6.87172 9.62539C7.11672 9.59039 7.46088 9.41539 7.64172 9.24039L12.2384 4.64372C13.0317 3.85039 13.4051 2.92872 12.2384 1.76206C11.0717 0.595392 10.1501 0.968725 9.35672 1.76206Z"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.69751 2.4209C9.08834 3.81507 10.1792 4.9059 11.5792 5.30257"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            {" "}
                                            Edit
                                        </button>
                                    </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="paginationDiv">
                            <button onClick={handlePrevPage}>
                                {
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M7.91003 3.03964V6.15464V8.95964C7.91003 9.43964 7.33003 9.67964 6.99003 9.33964L4.40003 6.74964C3.98503 6.33464 3.98503 5.65964 4.40003 5.24464L5.38503 4.25964L6.99003 2.65464C7.33003 2.31964 7.91003 2.55964 7.91003 3.03964Z"
                                            fill="#C1C1C1"
                                        />
                                    </svg>
                                }
                            </button>
                            {Array.from({length: totalPages}, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    disabled={currentPage === index + 1}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button onClick={handleNextPage}>
                                {
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.08997 3.03964V6.15464V8.95964C4.08997 9.43964 4.66997 9.67964 5.00997 9.33964L7.59997 6.74964C8.01497 6.33464 8.01497 5.65964 7.59997 5.24464L6.61497 4.25964L5.00997 2.65464C4.66997 2.31964 4.08997 2.55964 4.08997 3.03964Z"
                                            fill="#C1C1C1"
                                        />
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AdminIndividualBook;
