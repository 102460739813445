// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {postRequest} from "../routes/Routes";
import {getUser} from "../auth/Auth";
import {loadRoles, loadUser} from "../common/Common";

const AddUsers = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(false),
        [roles, setRoles] = useState([]),
        [userId] = useState(decodeURI(window.location.href.split('/').at(-1)));

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
    });

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadRoles(data.token).then(data => {
                setRoles(data);
                setLoadingContent(false);
            }).catch(() => null);
            if (userId !== 'add') {
                setLoadingContent(true);
                loadUser(userId, data.token).then(data => {
                    setFormData({...data, role: JSON.stringify(data.role)});
                    setLoadingContent(false);
                }).catch(() => null);
            }
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        if (formData.role === '') {
            toast.error("Please select a role!", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false);
            return;
        }
        let values = {
            ...formData,
            role: JSON.parse(formData.role).id,
            token: user.token,
            endpoint: formData.hasOwnProperty("id") ? '/api/user/update/' + formData.id : '/api/user'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/admin/dashboard/users');
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        navigate('/admin/dashboard/users');
    };

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Users</h1>
                            <span>Add/ Remove / Edit Users</span>
                        </div>
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="frm-row single-row">
                                    <div className="one-row">
                                        <label htmlFor="name">Name *</label>
                                        <input type="text" id="name" name="name"
                                               value={formData.name} onChange={handleChange}
                                               placeholder="John Thonmpson"
                                               required/>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="EditDescription">Email *</label>
                                        <input type="email" id="email" name="email"
                                               value={formData.email} onChange={handleChange}
                                               placeholder="user@email.com" required/>
                                    </div>
                                </div>
                                <div className="frm-row single-row">
                                    <div className="one-row halfRow">
                                        <label
                                            htmlFor="password">Password {formData.hasOwnProperty("id") ? "" : "*"}</label>
                                        <input type="password" id="password" name="password" value={formData.password}
                                               onChange={handleChange} placeholder="Password"
                                               required={!formData.hasOwnProperty("id")}/>
                                    </div>
                                    <div className="one-row">
                                        <label htmlFor="role">Role *</label>
                                        <select id="role" name="role" value={formData.role}
                                                onChange={handleChange}>
                                            <option value="">Select Role</option>
                                            {roles.map((item, index) => (
                                                <option value={JSON.stringify(item)} key={index}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="buttin-set-sub">
                                    <button type="button" className="onFormCancle" onClick={handleCancel}>Cancel
                                    </button>
                                    <button type="submit" className="onFormSubmit">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AddUsers;
