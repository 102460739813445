// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {getUser} from "../auth/Auth";
import {loadUsers} from "../common/Common";
import {deleteRequest} from "../routes/Routes";

const AdminUsers = () => {
    const navigate = useNavigate();
    const handleAdd = () => {
        navigate('/admin/dashboard/users/add');
    }
    const [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(true),
        [sampleData, setSampleData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const itemsPerPage = 3;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sampleData.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadUsers(data.token).then(data => {
                setSampleData(data);
                setLoadingContent(false);
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    useEffect(() => {
        // Update selectedRows when switching pages
        setSelectedRows([]);
    }, [currentPage]);

    const totalPages = Math.ceil(sampleData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCheckboxChange = (event, userId) => {
        if (event.target.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, userId]);
        } else {
            setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((id) => id !== userId)
            );
        }
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            setSelectedRows(sampleData.map((item) => item.id));
        } else {
            setSelectedRows([]);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleDeleteRows = () => {
        setLoading(true);
        selectedRows.forEach((selectedUser, index) => {
            deleteRequest('/api/user/' + selectedUser, user.token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        if (selectedRows.length === index + 1) {
                            setSampleData(values => values.filter(function (user) {
                                return !selectedRows.includes(user.id);
                            }));
                            setSelectedRows([]);
                            toast.success('Successfully deleted Users', {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                } else {
                    toast.error('An error occurred!', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            });
        });
    };

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Users</h1>
                            <span>Add/ Remove / Edit Users</span>
                        </div>
                        {/* add remove buttons */}
                        <div className="addRemBtnAdmiin">
                            <div className="flex-btn">
                                <button type="button" className="addRemBtn reRec" onClick={handleDeleteRows}
                                        disabled={selectedRows.length === 0}>
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Delete Record
                                </button>
                                <button type="button" className="addRemBtn addRec" onClick={handleAdd}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                         fill="none">
                                        <path d="M3.5 7H10.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                        <path d="M7 10.5V3.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                    Add Record
                                </button>
                            </div>
                        </div>
                        {/* pagination */}
                        <table>
                            <thead className="thead">
                            <tr>
                                <th><input
                                    type="checkbox"
                                    checked={
                                        selectedRows.length === sampleData.length &&
                                        selectedRows.length > 0
                                    }
                                    onChange={handleSelectAllChange}
                                    style={{marginRight: 22 + 'px'}}
                                />UserID
                                </th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.includes(item.id)}
                                            onChange={(e) => handleCheckboxChange(e, item.id)}
                                            style={{marginRight: 22 + 'px'}}

                                        />{item.id}</td>
                                    <td>{item.name}</td>
                                    <td style={{textTransform: "lowercase"}}>{item.email}</td>
                                    <td>{item.role.name}</td>
                                    <td>
                                        <button id="editdata" className="editBtn"
                                                onClick={() => navigate("/admin/dashboard/users/edit/" + item.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                 viewBox="0 0 14 14" fill="none">
                                                <path
                                                    d="M6.41663 1.16699H5.24996C2.33329 1.16699 1.16663 2.33366 1.16663 5.25033V8.75033C1.16663 11.667 2.33329 12.8337 5.24996 12.8337H8.74996C11.6666 12.8337 12.8333 11.667 12.8333 8.75033V7.58366"
                                                    stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M9.35672 1.76206L4.76005 6.35872C4.58505 6.53372 4.41005 6.87789 4.37505 7.12872L4.12422 8.88456C4.03088 9.52039 4.48005 9.96372 5.11588 9.87622L6.87172 9.62539C7.11672 9.59039 7.46088 9.41539 7.64172 9.24039L12.2384 4.64372C13.0317 3.85039 13.4051 2.92872 12.2384 1.76206C11.0717 0.595392 10.1501 0.968725 9.35672 1.76206Z"
                                                    stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8.69751 2.4209C9.08834 3.81507 10.1792 4.9059 11.5792 5.30257"
                                                      stroke="white" stroke-width="1.5" stroke-miterlimit="10"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="paginationDiv">
                            <button onClick={handlePrevPage}>
                                {<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                      fill="none">
                                    <path
                                        d="M7.91003 3.03964V6.15464V8.95964C7.91003 9.43964 7.33003 9.67964 6.99003 9.33964L4.40003 6.74964C3.98503 6.33464 3.98503 5.65964 4.40003 5.24464L5.38503 4.25964L6.99003 2.65464C7.33003 2.31964 7.91003 2.55964 7.91003 3.03964Z"
                                        fill="#C1C1C1"/>
                                </svg>}
                            </button>
                            {Array.from({length: totalPages}, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    disabled={currentPage === index + 1}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                onClick={handleNextPage}

                            >
                                {<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                      fill="none">
                                    <path
                                        d="M4.08997 3.03964V6.15464V8.95964C4.08997 9.43964 4.66997 9.67964 5.00997 9.33964L7.59997 6.74964C8.01497 6.33464 8.01497 5.65964 7.59997 5.24464L6.61497 4.25964L5.00997 2.65464C4.66997 2.31964 4.08997 2.55964 4.08997 3.03964Z"
                                        fill="#C1C1C1"/>
                                </svg>}
                            </button>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AdminUsers;
