// AdminDashboard.js

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "../assets/css/AdminDashboard.css";

import logo from "../assets/images/image.png";

import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

import {getUser} from "../auth/Auth";
import {loadGallery} from "../common/Common";
import {uploadFile} from "../routes/Routes";
import {toast} from "react-toastify";

const AddImgAdmin = () => {
    const navigate = useNavigate();
    const [image, setImage] = useState(null),
        [user, setUser] = useState(null),
        [loading, setLoading] = useState(false),
        [loadingContent, setLoadingContent] = useState(false),
        [galleryId] = useState(decodeURI(window.location.href.split('/').at(-1))),
        imageInput = React.createRef();

    const [formData, setFormData] = useState({
        imgTagAdd: '',
        addimg: '',
    });

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => {
            setUser(null);
        });
        if (galleryId !== 'add') {
            setLoadingContent(true);
            loadGallery(galleryId).then(data => {
                const tempFormData = {
                    imgTagAdd: data.tag,
                    id: data.id,
                    image_url: data.image_url
                }
                setFormData(tempFormData);
                setLoadingContent(false);
            }).catch(() => null);
        }
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const data = new FormData();
        data.append('tag', formData.imgTagAdd);
        if (formData.hasOwnProperty("id")) {
            if (imageInput.current.files.length > 0) {
                data.append('image', formData.image);
            }
        } else {
            if (imageInput.current.files.length > 0) {
                data.append('image', imageInput.current.files[0]);
            }
        }
        const endpoint = formData.hasOwnProperty("id") ? ('/api/gallery/update/' + formData.id) : '/api/gallery';
        uploadFile(data, endpoint, user.token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/admin/dashboard/gallery');
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        navigate('/admin/dashboard/gallery');
    };

    if (!loadingContent && user) {
        return (
            <div className="admin-dashboard">
                <Sidebar/>
                <div className="main-content">
                    <div className="cont-wrapper">
                        <div className="dashboardText">
                            <h1>Gallery</h1>
                            <span>Add/ Remove / Edit Gallery</span>
                        </div>
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="frm-row single-row">
                                    <div className="one-row halfRow">
                                        <label htmlFor="imgTagAdd">Image Category</label>
                                        <input type="text" id="imgTagAdd" name="imgTagAdd" value={formData.imgTagAdd}
                                               onChange={handleChange} placeholder="RFTL" required/>
                                    </div>
                                </div>
                                <div className="frm-row single-row">
                                    <div className="one-row halfRow">
                                        <label htmlFor="file-upload">Upload Image</label>
                                        <div className="custom-file">
                                            {image ?
                                                <img src={URL.createObjectURL(image)}/>
                                                : formData.hasOwnProperty("id") ?
                                                    <img src={formData.image_url}/>
                                                    : null
                                            }
                                            <input type="file" className="custom-file-input" id="gallery♠Image"
                                                   accept="image/*"
                                                   ref={imageInput}
                                                   onChange={(event) => {
                                                       setImage(imageInput.current.files[0]);
                                                       if (formData.hasOwnProperty("id")) {
                                                           setFormData(values => ({
                                                               ...values,
                                                               image: imageInput.current.files[0]
                                                           }))
                                                       }
                                                   }}/>
                                            <label className="custom-file-label" htmlFor="gallery♠Image">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="38"
                                                     height="38" id="add-image-icon" style={{marginBottom: "2%"}}
                                                     alt="Add-Icon">
                                                    <path fill="none" d="M0 0h24v24H0z"/>
                                                    <path
                                                        d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
                                                        fill="rgba(73,80,87,1)"/>
                                                </svg>
                                                <div className="image-uploader-label">
                                                    Drag & drop or select from your files
                                                </div>
                                            </label>
                                            <small id="image" className="form-text text-muted">
                                                Add Gallery Image
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="buttin-set-sub">
                                    <button type="button" className="onFormCancle" onClick={handleCancel}>Cancel
                                    </button>
                                    <button type="submit" className="onFormSubmit" onChange={handleSubmit}>
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* profile section */}
                <div className="profile-section">
                    <img src={logo} alt="Admin Profile" className="profile-image"/>
                    <p className="admin-name">Welcome {user.name}</p>
                </div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
};

export default AddImgAdmin;
